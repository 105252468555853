import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.underBanner}>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('home.underbanner.text_01')}
                        </p>
                        <p>
                            {t('home.underbanner.text_02')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
