import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AssetsPage/banner.png';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className={`container ${classes.container}`}>
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-60`}>
                            <span>
                                {t('assets.banner.title')}
                            </span>
                        </h1>
                        <p className={`${classes.text} font-16`}>
                            {t('assets.banner.text')}
                        </p>
                        <div className="mt-btn">
                            <Button>
                                {t('btn.signup')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <img className={classes.image} src={image} alt=''/>
        </section>
    );
};

export default Banner;
