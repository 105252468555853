import React, { useState } from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import true_svg from '../../../assets/img/HomePage/Accounts/true.svg';
import false_svg from '../../../assets/img/HomePage/Accounts/false.svg';

const accounts = [
    {
        title: 'NANO',
        rows: [
            'home.accounts.bid',
            '$250',
            'TRADE Platform',
            '0.00003',
            '1:400',
            '6 USD/lot',
            'home.accounts.commissions_cfd.01',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100 000',
            'home.accounts.unlimited',
            true,
            'home.accounts.month'
        ],
    },
    {
        title: 'STANDARD',
        rows: [
            'home.accounts.bid',
            '$1.000',
            'TRADE Platform',
            '0.00013',
            '1:400',
            false,
            'home.accounts.commissions_cfd.01',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100 000',
            'home.accounts.unlimited',
            true,
        ],
    },
    {
        title: 'MAX',
        rows: [
            'home.accounts.bid',
            '$15.000',
            'TRADE Platform',
            '0.0002',
            '1:400',
            false,
            'home.accounts.commissions_cfd.03',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100 000',
            'home.accounts.unlimited',
            true,
        ],
    },
    {
        title: 'TERRA',
        rows: [
            'home.accounts.bid',
            '$50.000',
            'TRADE Platform',
            '0.00003',
            '1:777',
            '6 USD/Lot',
            'home.accounts.commissions_cfd.04',
            'EUR, USD',
            true,
            true,
            '0.01',
            '100 000',
            'home.accounts.unlimited',
            true,
        ],
    },
];

const rowTitles = [
    'home.accounts.row_title_01',
    'home.accounts.row_title_02',
    'home.accounts.row_title_03',
    'home.accounts.row_title_04',
    'home.accounts.row_title_05',
    'home.accounts.row_title_06',
    'home.accounts.row_title_07',
    'home.accounts.row_title_08',
    'home.accounts.row_title_09',
    'home.accounts.row_title_10',
    'home.accounts.row_title_11',
    'home.accounts.row_title_12',
    'home.accounts.row_title_13',
    'home.accounts.row_title_14',
    'home.accounts.row_title_15',
];

const Accounts = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        {t('home.accounts.title')}
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        {t('home.accounts.text')}
                    </p>
                    <p className={classes.desc}>
                        {t('home.accounts.desc')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
                <div className={classes.accountsWrap}>
                    <div className={classes.buttons}>
                        {accounts.map((account, index) =>
                            <div
                                key={index}
                                onClick={() => setActive(index)}
                                className={`${classes.button} ${active === index ? classes.active : ''} no-select`}
                            >
                                {t(account.title)}
                            </div>
                        )}
                    </div>
                    <div className={classes.accounts}>
                        {accounts[active].rows.map((row, index) =>
                            <div key={index} className={classes.row}>
                                <div className={classes.col}>
                                    {t(rowTitles[index])}
                                </div>
                                <div className={classes.col}>
                                    {typeof (row) === 'boolean' ? (
                                        row === true ?
                                            <img src={true_svg} alt='' />
                                            :
                                            <img src={false_svg} alt='' />
                                    ) : (
                                        <>
                                            {t(row)}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Accounts;
