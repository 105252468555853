import React, { useState } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const tabs = [
    {
        title: 'fees.underbanner.tab_01.title',
        text: 'fees.underbanner.tab_01.text',
    },
    {
        title: 'fees.underbanner.tab_02.title',
        text: 'fees.underbanner.tab_02.text',
    },
    {
        title: 'fees.underbanner.tab_03.title',
        text: 'fees.underbanner.tab_03.text',
    },
    {
        title: 'fees.underbanner.tab_04.title',
        text: 'fees.underbanner.tab_04.text',
    },
    {
        title: 'fees.underbanner.tab_05.title',
        text: 'fees.underbanner.tab_05.text',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container">
            <div className={classes.underBanner}>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            className={`${classes.tab} ${active === index ? classes.active : ''}`}
                            onClick={() => setActive(index)}
                        >
                            <h4 className={`${classes.tabTitle} font-20`}>
                                {t(tab.title)}
                            </h4>
                            <p className={`${classes.tabText} font-16`}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.textWrap}>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('fees.underbanner.text_01')}
                        </p>
                        <p>
                            {t('fees.underbanner.text_02')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
