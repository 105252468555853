import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";

const Button = ({ children, login, href }) => {
  const { lang } = useParams();
  return (
    <Link
      className={`${classes.button} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}`
        : `/${lang}/${href}`
      }
    >
      <span>{children}</span>
    </Link>
  );
};

export default Button;
