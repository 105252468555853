import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Explore from "../../components/HomePage/Explore/Explore";
import Banner from "../../components/AssetsPage/Banner/Banner";
import Tabs from "../../components/AssetsPage/Tabs/Tabs";

const AssetsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo · Trading assets</title>
        <meta name="title" content="Diversify Your Portfolio with a Wide Range of Trading Assets" />
        <meta property="og:title" content="Diversify Your Portfolio with a Wide Range of Trading Assets" />
        <meta property="twitter:title" content="Diversify Your Portfolio with a Wide Range of Trading Assets" />

        <meta name="description" content="Brabo offers an extensive selection of trading assets, including stocks, currencies, commodities, cryptocurrencies, and indices. Explore global markets, diversify your portfolio, and seize trading opportunities. Trade with confidence using our intuitive platform and make informed decisions to achieve your financial goals." />
        <meta property="og:description" content="Brabo offers an extensive selection of trading assets, including stocks, currencies, commodities, cryptocurrencies, and indices. Explore global markets, diversify your portfolio, and seize trading opportunities. Trade with confidence using our intuitive platform and make informed decisions to achieve your financial goals." />
        <meta property="twitter:description" content="Brabo offers an extensive selection of trading assets, including stocks, currencies, commodities, cryptocurrencies, and indices. Explore global markets, diversify your portfolio, and seize trading opportunities. Trade with confidence using our intuitive platform and make informed decisions to achieve your financial goals." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Tabs />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AssetsPage;
