import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Explore from "../../components/HomePage/Explore/Explore";
import Newsletters from "../../components/FaqPage/Newsletters/Newsletters";
import Faq from "../../components/FaqPage/Faq/Faq";
import UnderBanner from "../../components/FaqPage/UnderBanner/UnderBanner";
import Banner from "../../components/FaqPage/Banner/Banner";

const FaqPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo · Support and FAQ</title>
        <meta name="title" content="Dedicated Support and Comprehensive FAQ on Brabo" />
        <meta property="og:title" content="Dedicated Support and Comprehensive FAQ on Brabo" />
        <meta property="twitter:title" content="Dedicated Support and Comprehensive FAQ on Brabo" />

        <meta name="description" content="Brabo is committed to providing exceptional customer support. Our knowledgeable support team is available to assist you with any inquiries or concerns you may have. Additionally, our comprehensive FAQ section covers a wide range of common questions, providing quick and helpful answers. We're here to support you throughout your trading journey." />
        <meta property="og:description" content="Brabo is committed to providing exceptional customer support. Our knowledgeable support team is available to assist you with any inquiries or concerns you may have. Additionally, our comprehensive FAQ section covers a wide range of common questions, providing quick and helpful answers. We're here to support you throughout your trading journey." />
        <meta property="twitter:description" content="Brabo is committed to providing exceptional customer support. Our knowledgeable support team is available to assist you with any inquiries or concerns you may have. Additionally, our comprehensive FAQ section covers a wide range of common questions, providing quick and helpful answers. We're here to support you throughout your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Faq />
        <Newsletters />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
