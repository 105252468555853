import React from "react";
import classes from './TheseTools.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ToolsPage/these_tools.jpg';
import Button from "../../GlobalComponents/Button/Button";

const TheseTools = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.theseTools}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.content}>
                    <h2 className="font-40">
                        {t('tools.these_tools.title')}
                    </h2>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('tools.these_tools.text_01')} 
                        </p>
                        <p>
                            {t('tools.these_tools.text_02')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TheseTools;
