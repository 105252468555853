import HomePage from '../pages/HomePage/HomePage';
import AssetsPage from '../pages/AssetsPage/AssetsPage';
import ToolsPage from '../pages/ToolsPage/ToolsPage';
import FeesPage from '../pages/FeesPage/FeesPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/assets', element: AssetsPage },
    { path: '/:lang/tools', element: ToolsPage },
    { path: '/:lang/fees', element: FeesPage },
    { path: '/:lang/support', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { id: 1, path: 'assets', text: 'header.assets' },
    { id: 2, path: 'tools', text: 'header.tools' },
    { id: 3, path: 'fees', text: 'header.fees' },
    { id: 4, path: 'support', text: 'header.support' },
    { id: 5, path: 'about', text: 'header.about' },
];
