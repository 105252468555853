import React, { useState } from "react";
import classes from './Banner.module.scss';
import lines from '../../../assets/img/lines/lines_small.svg';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        title: 'about.banner.card_01.title',
        text: 'about.banner.card_01.text',
    },
    {
        title: 'about.banner.card_02.title',
        text: 'about.banner.card_02.text',
    },
    {
        title: 'about.banner.card_03.title',
        text: 'about.banner.card_03.text',
    },
    {
        title: 'about.banner.card_04.title',
        text: 'about.banner.card_04.text',
    },
    {
        title: 'about.banner.card_05.title',
        text: 'about.banner.card_05.text',
    },
    {
        title: 'about.banner.card_06.title',
        text: 'about.banner.card_06.text',
    },
    {
        title: 'about.banner.card_07.title',
        text: 'about.banner.card_07.text',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    return (
        <section className="container">
            <div className={classes.keyFeatures}>
                <div className={classes.head}>
                    <div className={classes.title}>
                        <h2 className="font-40">
                            {t('about.banner.title')}
                        </h2>
                        <img className={classes.lines} src={lines} alt='' />
                    </div>
                    <div className={classes.line}></div>
                    <p className={`${classes.text} font-16`}>
                        {t('about.banner.text')}
                    </p>
                </div>
                <div className={classes.swiperWrap}>
                    <div className={classes.nav}>
                        <div
                            className={classes.navBtn}
                            onClick={() => swiper.slidePrev()}
                        ></div>
                        <div
                            className={`${classes.navBtn} ${classes.right}`}
                            onClick={() => swiper.slideNext()}
                        ></div>
                    </div>
                    <Swiper
                        modules={[Navigation]}
                        className="aboutBannerSwiper no-select"
                        grabCursor
                        spaceBetween={20}
                        slidesPerView={1}
                        onInit={(e) => { setSwiper(e) }}
                        breakpoints={{
                            1220: {
                                slidesPerView: 3,
                                spaceBetween: 36,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="aboutBannerSwiperSlide">
                                <div className={`
                                    ${classes.card} 
                                    ${!(index % 2) ? classes.dark : classes.light}    
                                    font-16
                                `}>
                                    <h4 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={classes.cardText}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Banner;
