import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ToolsPage/Banner/Banner";
import UnderBanner from "../../components/ToolsPage/UnderBanner/UnderBanner";
import TheseTools from "../../components/ToolsPage/TheseTools/TheseTools";
import Explore from "../../components/HomePage/Explore/Explore";
import Advanced from "../../components/ToolsPage/Advanced/Advanced";

const ToolsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo · Trading tools</title>
        <meta name="title" content="Powerful Trading Tools for Enhanced Trading Experience" />
        <meta property="og:title" content="Powerful Trading Tools for Enhanced Trading Experience" />
        <meta property="twitter:title" content="Powerful Trading Tools for Enhanced Trading Experience" />

        <meta name="description" content="Brabo provides a suite of advanced trading tools designed to enhance your trading experience. Benefit from real-time market data, advanced charting, risk management tools, and customizable indicators. Execute trades with precision, analyze market trends, and maximize your trading potential with our robust set of trading tools." />
        <meta property="og:description" content="Brabo provides a suite of advanced trading tools designed to enhance your trading experience. Benefit from real-time market data, advanced charting, risk management tools, and customizable indicators. Execute trades with precision, analyze market trends, and maximize your trading potential with our robust set of trading tools." />
        <meta property="twitter:description" content="Brabo provides a suite of advanced trading tools designed to enhance your trading experience. Benefit from real-time market data, advanced charting, risk management tools, and customizable indicators. Execute trades with precision, analyze market trends, and maximize your trading potential with our robust set of trading tools." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <TheseTools />
        <Advanced />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ToolsPage;
